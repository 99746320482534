import React from "react";
import PropTypes from "prop-types";
import {StaticQuery, graphql} from "gatsby";
import GlobalStyle from "../styles/global";
import Header from "./core/Header";
import Footer from "./core/Footer";
// import HeaderText from './core/HeaderText';
import {GridThemeProvider} from "styled-bootstrap-grid";
import {ThemeProvider} from "styled-components";
import CustomCursor from "./core/CustomCursor";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Cursor from "./core/Cursor";
import {Helmet} from "react-helmet";

const gridTheme = {
  breakpoints: {
    xl: 1400,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 575,
  },
  row: {
    padding: 0,
  },
  col: {
    padding: 0,
  },
  container: {
    padding: 0,
    maxWidth: {
      xl: 1600,
      lg: 1600,
      md: 1600,
      sm: 540,
      xs: 540,
    },
  },
};

const styledTheme = {};

const Layout = ({children, colorScheme}) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => {
      return (
        <React.Fragment>
          <Helmet>
            <script type={"text/javascript"}>
              {`!function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '815330872859543');
              fbq('track', 'PageView');`}
            </script>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-HD4ZLQD34Z"></script>
            <script type={"text/javascript"}>
              {`window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
              
                gtag('config', 'G-HD4ZLQD34Z');`}
            </script>
            <noscript>{`<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=815330872859543&ev=PageView&noscript=1" />`}</noscript>
            <meta name="facebook-domain-verification" content="oh7qdyhr2z6zayqht2da5jbz11m82t"/>
          </Helmet>
          <ThemeProvider theme={styledTheme}>
            <GridThemeProvider gridTheme={gridTheme}>
              {/* <CustomCursor/> */}
              <Cursor/>
              <GlobalStyle/>
              <Header colorScheme={colorScheme}/>
              {/*<HeaderText colorScheme={colorScheme} />*/}
              {children}
              <Footer/>
            </GridThemeProvider>
          </ThemeProvider>
        </React.Fragment>
      );
    }}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
