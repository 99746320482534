import React, { useEffect } from "react";
import styled from "styled-components";
import breakpoints from "../../styles/breakpoints";
import theme from "../../styles/theme";

const CustomCursor = styled.div`
  display: none;
  @media (min-width: ${breakpoints.medium}) {
    height: 22px;
    width: 22px;
    border: 1px solid ${theme.colors.black};
    display: block;
    position: fixed;
    border-radius: 50%;
    z-index: 1111111111111122223;
    transition: .2s;
    left: 0;
    top: 0;
    pointer-events: none;
    transform: translate(-50%, -50%);
    &.dot-white {
      width: 14px;
      height: 14px;
      background-color: ${theme.colors.white} !important;
      border-color: ${theme.colors.white} !important;
      transition: 0.5s;
      transition-property: width, height, background-color;
      opacity: .6;
    }
    &.dot-black {
      width: 14px;
      height: 14px;
      background-color: ${theme.colors.black} !important;
      transition: 0.5s;
      border-color: ${theme.colors.black} !important;
      transition-property: width, height, background-color;
      opacity: .6;
    }
    &.black {
      border-color: ${theme.colors.black};
      &.dot {
        width: 14px;
        height: 14px;
        background-color: ${theme.colors.black};
      }
    }
    &.white,
    &.hero,
    &.menu {
      border-color: ${theme.colors.white};
      &.dot {
        width: 10px;
        height: 10px;
        background-color: ${theme.colors.white};
      }
    }
  }
`;

const Cursor = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.innerWidth > 768) {
        let cursor = document.getElementById("custom-cursor");
        document.addEventListener("mousemove", (e) => {
          cursor.style.cssText = "left: " + e.clientX + "px; top: " + e.clientY + "px"
        })
        let blackCursor = document.getElementsByClassName("cursor-black")
        let whiteCursor = document.getElementsByClassName("cursor-white")
        let whiteDotCursor = document.getElementsByClassName("cursor-dot-white")
        let blackDotCursor = document.getElementsByClassName("cursor-dot-black")
        let dotCursor = document.getElementsByClassName("cursor-dot")
    
        for (let i = 0; i < blackCursor.length; i ++) {
          blackCursor[i].addEventListener("mouseover", e => {
            cursor.classList.add("black")
            cursor.classList.remove("white")
       
          })
          blackCursor[i].addEventListener("mouseleave", e => {
            cursor.classList.remove("black")
          })
        }
        for (let i = 0; i < whiteCursor.length; i ++) {
          whiteCursor[i].addEventListener("mouseover", e => {
            cursor.classList.add("white")
            cursor.classList.remove("black")
    
          })
          whiteCursor[i].addEventListener("mouseleave", e => {
            cursor.classList.remove("white")
          })
        }
        for (let i = 0; i < whiteDotCursor.length; i ++) {
          whiteDotCursor[i].addEventListener("mouseover", e => {
            cursor.classList.add("dot-white")
          })
          whiteDotCursor[i].addEventListener("mouseleave", e => {
            cursor.classList.remove("dot-white")
          })
        }
        for (let i = 0; i < blackDotCursor.length; i ++) {
          blackDotCursor[i].addEventListener("mouseover", e => {
            cursor.classList.add("dot-black")
          })
          blackDotCursor[i].addEventListener("mouseleave", e => {
            cursor.classList.remove("dot-black")
          })
        }
        for (let i = 0; i < dotCursor.length; i ++) {
          dotCursor[i].addEventListener("mouseover", e => {
            cursor.classList.add("dot")
          })
          dotCursor[i].addEventListener("mouseleave", e => {
            cursor.classList.remove("dot")
          })
        }
      }
    }
  })

  return <CustomCursor id={"custom-cursor"}/>;
};

export default Cursor;
