import React, {Component} from 'react';
import styled, {keyframes} from 'styled-components';
import LogoS from '../../resources/images/profile_page/Logo.svg';
import DarkLogoS from '../../resources/images/profile_page/dark-logo.svg';
import BurgerMenuLight from '../../resources/images/profile_page/burger-menu.svg';
import BurgerMenuDark from '../../resources/images/profile_page/burger-menu-dark.svg';
import fonts from '../../styles/fonts';
import BurgerMenuClose from '../../resources/images/profile_page/close-menu.svg';
import {Link, StaticQuery} from 'gatsby';
import {Col, Container, Row} from 'styled-bootstrap-grid';
import Img from 'gatsby-image';
import theme from '../../styles/theme';
import ExternalLink from "./ExternalLink";
import SocialLinks from "./SocialLinks";
import { getProjectLink } from "../../services/links";

const MenuAnimation = (bottom) => keyframes`
  0% {
    opacity: 0;
  }
  60% {
    opacity: .8;
    bottom: 4px;
  }
  100% {
    bottom: ${bottom}px;
    opacity: 1;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  padding: 25px 35px 20px 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  box-sizing: border-box;
  height: 110px;
  z-index: 999999;
  @media(min-width: ${theme.breakpoints.medium}) {
    width: auto;
    height: 220px;
    padding-bottom: 20px;
  }
  @media(min-width: ${theme.breakpoints.large}) {
    padding-left: 61px;
    padding-right: 61px;
  }
`;

const ContainerStyled = styled(Container)`
  max-width: 100% !important;
  width: 50%;
  @media(min-width: ${theme.breakpoints.medium}) {
    padding-left: 70px;
    width: 100%;
  }
`;

const Menu = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  height: 66px;
  width: auto;
  padding: 0;
  position: relative;
  cursor: pointer;
  @media(min-width: ${theme.breakpoints.medium}) {
    padding-bottom: 15px;
    padding-right: ${props => props.open === 'false' && '11.6px'};
  }
`;

const BurgerMenu = styled.img`
  width: ${props => props.open === 'false' ? '39.5px' : '27.9px'};
  height: ${props => props.open === 'false' ? '12.5px' : '27.9px'};
  padding-top: ${props => props.open === 'false' && '15.4px'};
  cursor: pointer;
  display: ${props => props.desktop && 'none'};
  @media(min-width: ${theme.breakpoints.medium}) {
    display: ${props => props.desktop && 'block'};
    display: ${props => props.mobile && 'none'};
  }
`;

const OverlayAnimation = keyframes`
  0% {
    opacity: 0;
  }
  20% {
    opacity: .8;
  }
  100% {
    opacity: 1;
  }
`;

const MenuOverlay = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  top: ${props => props.fullHeight === true && '0'};
  bottom: ${props => props.fullHeight === true && '0'};
  position: ${props => props.fullHeight === true ? 'fixed' : 'fixed'};
  z-index: ${props => props.open ? 99 : -1};  
  display: flex;
  flex-direction: row;
  overflow-y: ${props => props.fullHeight === true ? 'scroll' : 'hidden'};
  transition: 0.4s ease-out;
  background-color: ${theme.colors.black};
  opacity: ${props => props.open ? 1 : 0};

  ::-webkit-scrollbar {
    display: none;
  }
  @media(min-width: ${theme.breakpoints.medium}){
    animation: ${props => props.visible && OverlayAnimation} .4s linear 0s forwards;
  }
`;

const Left = styled.div`
  background: ${theme.colors.black};
  width: 100%;
  padding-top: 140px;
  box-sizing: border-box;
  
  @media(min-width: ${theme.breakpoints.medium}) {
    height: 100%;
    width: 50%;
    padding: 100px 30px;
  }
  
  @media(min-width: ${theme.breakpoints.large}) {
    padding: 100px 60px;
  }

  @media(max-height: 800px){
    overflow: scroll;
  }
`;

const StyledLink = styled(Link)`
  ${fonts.CircularMedium};
  letter-spacing: 0;
  font-size: 35px;
  line-height: 52.2px;
  color: ${theme.colors.darkGrey};
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  border: none;
  outline: none;
  transition: .2s;
  position: absolute;
  bottom: -52.2px;
  opacity: 0;
  animation: ${props => props.visible && MenuAnimation(0)} .4s linear ${props => props.index && props.index * 0.1}s forwards;
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    transition: .2s;
    border: none;
    outline: none;
    box-shadow: none;
    color: ${theme.colors.white};
  }
  &.active {
    color: ${theme.colors.white};
  }
  @media(min-width: ${theme.breakpoints.medium}) {
    font-size: 62px;
    line-height: 82.4px;
    transition: .5s;
    bottom: -82.4px;
  }
`;

const LinkContainer = styled.div`
  overflow: hidden;
  position: relative;
  height: 54.4px;
  @media(min-width: ${theme.breakpoints.medium}) {
    height: 84.4px;
  }
`

const MenuLinks = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  text-align: left;
  margin: auto;
  padding: 0;
  @media(min-width: ${theme.breakpoints.medium}) {
    padding-left: 70px;
    padding-top: 60px;
    box-sizing: border-box;
    width: 100%;
  }
`;

const ColStyled = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const RowStyled = styled(Row)`
  padding-top: ${props => props.project ? '0px' : '70px'};
  width: 100%;
  text-align: left;
  margin: auto;
`;

const Title = styled.div`
  ${fonts.MaisonNeueDemi};
  font-size: 18px;
  line-height: 28px;
  color: ${theme.colors.white};
  margin-top: 12px;
`;

const Description = styled.div`
    ${fonts.MaisonNeueLight};
    font-size: 18px;
    line-height: 28px;
    color: ${theme.colors.darkGrey};
    padding-bottom: 35px;
    a {
      color: ${theme.colors.darkGrey};
      text-decoration: none;
      transition: .2s;
      ${fonts.MaisonNeueLight};
      font-size: 18px;
      line-height: 28px;
      &:hover {
        color: ${theme.colors.white};
        transition: .2s;
      }
    }
`;

const SocialIcons = styled.div`
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-left: -15px;
`;

const Right = styled.div`
  display: none;
  ::-webkit-scrollbar {
    display: none;
  }
  @media(min-width: ${theme.breakpoints.medium}) {
    display: block;
    height: 100%;
    width: 50%;
    background: ${theme.colors.greyConnectBlock};
    padding:  100px 60px;
    box-sizing: border-box;
  }
  @media(max-width: 991px){
      overflow: scroll;
  }
  @media(max-height: 800px){
      overflow: scroll;
  }
`;

const ProjectsTitle = styled.div`
  ${fonts.CircularMedium};
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0;
  color: ${theme.colors.white};
  padding-bottom: 35px;
`;

const Project = styled.div`
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
  box-sizing: border-box;
`;

const ProjectImg = styled(Img)`
  width: 100%;
  height: auto;
  transition: .4s;
  transform: ${props => props.hovered ? 'scale(1.15)' : 'scale(1)'};
`;

const HomeLink = styled(Link)`
  text-decoration: none;
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    outline: none;
    box-shadow: none;
    border: none;
  }  
`;

const ProjectsScroll = styled(Container)`
  width: 100%;
  height: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const ProjectImgBlock = styled.div`
  width: 100%;
  height: auto;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  position: relative;
`;

const StyledButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  display: ${props => props.desktop ? "none" : "block"};
  @media(min-width: ${theme.breakpoints.medium}){
    display: block;
  }
`;

const ImageLink = styled(Link)`
  z-index: 9999;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const Circle = styled.div`
  width: 2000px;
  height: 2000px;
  position: fixed;
  border-radius: 50%;
  transition: 0.9s;
  background: ${theme.colors.black};
  z-index: 1;
  top: 0;
  left: 0;
  transform: ${props => props.open? "translate(-47.43%, -45.9%)scale(1);" : "translate(-47.43%, -45.9%)scale(0)"};
  @media (min-width: ${theme.breakpoints.medium}) {
    transform: ${props => props.open? "translate(-47.28%, -44.3%)scale(1);" : "translate(-47.28%, -44.3%)scale(0)"};
  }

  @media (min-width: ${theme.breakpoints.large}) {
    width: 5000px; 
    height: 5000px;
    left: -12px;
    top: -7px;
    transform: ${props => props.open? "translate(-48.15%, -47.6%)scale(1);" : "translate(-48.15%, -47.6%)scale(0)"};

  }
`

const ProjectCol = styled(Col)`
  min-height: 100%;
  overflow: hidden;
`

const ProjectCardContainer = styled.div`
  opacity: 0;
  animation: ${props => props.visible && MenuAnimation(0)} .45s linear ${props => props.index && props.index * 0.1}s forwards;
  position: relative;
  bottom: -100px;

  @media (min-width: ${theme.breakpoints.large}) {
    animation: ${props => props.visible && MenuAnimation(0)} .45s linear ${props => props.first && props.first ? 0.1 : 0.2}s forwards;
    bottom: -150px;

  }

`

class Header extends Component {
    state = {
        isMenuOpen: false,
        width: 0,
        height: 0,
        isHomepage: true,
        isOverlayVisible: null,
        isHovered: null,
    };

    openMenu = () => this.setState({
        isMenuOpen: !this.state.isMenuOpen
    });

    componentDidMount = () => {
        if (typeof window !== 'undefined') {
            let location = window.location;
            let isHomepage = location.pathname === '/';

            this.setState({
              isHomepage: isHomepage
            });

            this.updateWindowDimensions();
            window.addEventListener('resize', this.updateWindowDimensions);
        }
    };

    componentWillUnmount = () => {
        if (typeof window !== 'undefined') {
            window.removeEventListener('resize', this.updateWindowDimensions);
        }
    };

    updateWindowDimensions = () => {
        if (typeof window !== 'undefined') {
            this.setState({ width: window.innerWidth, height: window.innerHeight });
        }
    };

    componentDidUpdate() {
      let cursor = document.getElementById("custom-cursor");
      if (this.state.isMenuOpen) {
        cursor.classList.add("menu")
      }
      if (!this.state.isMenuOpen) {
        cursor.classList.remove("menu")
      }
    }

    navData = [
        {
            label: "Profile",
            url: '/profile'
        },
        {
            label: "Projects",
            url: '/projects'
        },
        {
            label: "Lately",
            url: '/lately'
        },
        {
            label: "Futures",
            url: '/futures'
        },
        {
            label: "Contact",
            url: '/contact'
        },
    ];

    render() {
        const { isMenuOpen, height, isHomepage, isHovered } = this.state;
        const { colorScheme, projects } = this.props;
        const BurgerMenuOpen = colorScheme === 'dark' ? BurgerMenuDark : BurgerMenuLight;
        return (
            <React.Fragment>
                <HeaderContainer open={isMenuOpen} colorScheme={colorScheme} id={"header"} className={isMenuOpen && colorScheme === 'dark' ? 'cursor-black' : !isMenuOpen && colorScheme === 'dark' ? 'cursor-black' : 'cursor-white'}>
                    <Menu>
                        {isHomepage === false ?
                            <HomeLink to={'/'}>
                              <Logo src={isMenuOpen && colorScheme === 'dark' ? LogoS : !isMenuOpen && colorScheme === 'dark' ? DarkLogoS : LogoS} open={isMenuOpen}/>
                            </HomeLink>
                            :
                            <Logo src={isMenuOpen && colorScheme === 'dark' ? LogoS : !isMenuOpen && colorScheme === 'dark' ? DarkLogoS : LogoS} open={isMenuOpen}/>
                        }
                        <StyledButton desktop onClick={() => this.openMenu()}>
                            <BurgerMenu src={isMenuOpen ? BurgerMenuClose : BurgerMenuOpen} open={isMenuOpen}/>
                        </StyledButton>
                    </Menu>
                    <BurgerMenu src={isMenuOpen ? BurgerMenuClose : BurgerMenuOpen}
                                open={isMenuOpen} mobile onClick={() => this.openMenu()}/>
                </HeaderContainer>
                <MenuOverlay open={isMenuOpen} className="cursor-white">
                    <Left fullHeight={height < 1000}>
                        <MenuLinks>
                            {this.navData.map((item, index) => {
                                return (
                                    <LinkContainer key={index} visible={isMenuOpen}>
                                        <StyledLink to={item.url} onClick={() => this.openMenu()}
                                                    visible={isMenuOpen}
                                                    index={index}
                                                    activeClassName={"active"}><span className="cursor-dot-white">{item.label}</span></StyledLink>
                                    </LinkContainer>
                                )
                            })}
                        </MenuLinks>
                        <ContainerStyled>
                            <RowStyled>
                                <ColStyled lg={6}>
                                    <Title>Find us</Title>
                                    <Description className="cursor-dot-white">
                                        <ExternalLink href="https://goo.gl/maps/MAvtttuqJvjei8GZ9">
                                            Arena Park,<br/>
                                            Leeds, LS17 9BF<br/>
                                        </ExternalLink>
                                        <ExternalLink href="tel:+441132385471">+44 (0)113 238 5471</ExternalLink>
                                    </Description>
                                </ColStyled>
                                <ColStyled lg={6}>
                                    <Title>Follow us</Title>
                                    <SocialIcons>
                                        <SocialLinks/>
                                    </SocialIcons>
                                </ColStyled>
                            </RowStyled>
                        </ContainerStyled>
                    </Left>
                    <Right fullHeight={height < 1000}>
                        <ProjectsTitle>Latest projects</ProjectsTitle>
                        <ProjectsScroll>
                            <RowStyled project>
                                {projects.map((project, index) => {
                                    return (
                                        <ProjectCol lg={6} key={index}>
                                          <ProjectCardContainer visible={isMenuOpen} index={index} first={index === 0 || index === 1}>
                                            <Project>
                                                <ProjectImgBlock className="cursor-dot-white">
                                                    <ImageLink to={getProjectLink(project.node.slug)}
                                                               onMouseEnter={() => this.setState({isHovered: index})}
                                                               onMouseLeave={() => this.setState({isHovered: null})}/>

                                                    <ProjectImg fluid={project.node.thumbnail.fluid}
                                                                hovered={isHovered === index}/>
                                                </ProjectImgBlock>
                                                <Title>{project.node.clientName}</Title>
                                                <Description>{project.node.title}</Description>
                                            </Project>
                                          </ProjectCardContainer>
                                        </ProjectCol>
                                    )
                                })}
                            </RowStyled>
                        </ProjectsScroll>
                    </Right>
                </MenuOverlay>
                <Circle open={isMenuOpen}/>
            </React.Fragment>
        )
    }
}

export default ({ colorScheme }) => (
    <StaticQuery
        query={graphql`
      query {
        allContentfulProject(sort: { fields: position, order: ASC }, limit: 4, filter: {featured: {eq: true}}) {
          edges {
            node {
              clientName
              title
              slug
              thumbnail: thumbnail {
                fluid(
                  maxWidth: 500
                  maxHeight: 345
                  quality: 70
                ) {
                  ...GatsbyContentfulFluid_withWebp
                }
              }
            }
          }
        }
      }
    `}
        render={(data) => (
            <Header projects={data.allContentfulProject.edges} colorScheme={colorScheme} />
        )}
    />
)
