import { createGlobalStyle } from 'styled-components'
import MaisonNeueBookWoff from '../resources/fonts/MaisonNeue-Book.woff';
import MaisonNeueBookWoff2 from '../resources/fonts/MaisonNeue-Book.woff2';
import MaisonNeueDemiWoff from '../resources/fonts/MaisonNeue-Demi.woff';
import MaisonNeueDemiWoff2 from '../resources/fonts/MaisonNeue-Demi.woff2';
import MaisonNeueLightWoff from '../resources/fonts/MaisonNeue-Light.woff';
import MaisonNeueLightWoff2 from '../resources/fonts/MaisonNeue-Light.woff2';
import CircularMediumWoff from '../resources/fonts/lineto-circular-medium.woff';
import CircularMediumWoff2 from '../resources/fonts/lineto-circular-medium.woff2';

import reset from 'styled-reset';

const fontFace = (fontFamily, woff, woff2) => {
  return `
    @font-face {
        font-family: '${fontFamily}';
        src: url(${woff2}) format('woff2'),
        url(${woff}) format('woff');
        font-weight: normal;
        font-style: normal;
    }
  `;
};

const GlobalStyle = createGlobalStyle`
    ${reset}
    
    body {
     position: relative;
     text-rendering: optimizeLegibility;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
     // cursor: none;
     // div, a, button, input {
     //  cursor: none;
     // }
    }

    ${fontFace('MaisonNeueBook', MaisonNeueBookWoff, MaisonNeueBookWoff2)}
    ${fontFace('MaisonNeueDemi', MaisonNeueDemiWoff, MaisonNeueDemiWoff2)}
    ${fontFace('MaisonNeueLight', MaisonNeueLightWoff, MaisonNeueLightWoff2)}
    ${fontFace('CircularMedium', CircularMediumWoff, CircularMediumWoff2)}
`;

export default GlobalStyle
