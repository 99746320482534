import React, { Component } from "react";
import styled from "styled-components";
import FooterBackground from "../../resources/images/profile_page/footer-background.png";
import { Row, Col, Container } from "styled-bootstrap-grid";
import DarkLogo from "../../resources/images/profile_page/dark-logo.svg";
import fonts from "../../styles/fonts";
import PlusIcon from "../../resources/images/profile_page/plus.svg";
import MailchimpSubscribe from "./NewsLetter/MailchimpSignUp";
import theme from "../../styles/theme";
import ExternalLink from "./ExternalLink";
import { Link } from "gatsby";
import SocialLinks from "./SocialLinks";

const FooterContainer = styled.div`
  background: ${theme.colors.white} url(${FooterBackground}) repeat;
  background-size: cover;
  height: auto;
  width: calc(100% - 30px);
  padding: 50px 25px 35px 25px;
  margin: 15px;
  box-sizing: border-box;
  @media (min-width: ${theme.breakpoints.medium}) {
    margin: 70px 40px 30px 40px;
    box-sizing: border-box;
    width: calc(100% - 80px);
    height: 292px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

const FooterDark = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  padding: 40px 40px 30px 40px;
  background: ${theme.colors.black};
  @media (min-width: ${theme.breakpoints.medium}) {
    padding: 0 35px;
  }
`;

const ColStyled = styled(Col)`
  height: auto;
  display: ${(props) => (props.desktop ? "none" : "flex")};
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: ${(props) => props.details && "10px"};
  @media (min-width: ${theme.breakpoints.medium}) {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`;

const RowStyled = styled(Row)`
  height: ${(props) => (props.open === "true" ? "50%" : "100%")};
  position: relative;
  @media (min-width: ${theme.breakpoints.medium}) {
    height: ${(props) => props.main && "76px"};
  }
`;

const Logo = styled.img`
  height: 73px;
  width: auto;
`;

const LogoMobile = styled.img`
  height: 60px;
  width: auto;
  position: absolute;
  top: 5px;
  right: 0;
  @media (min-width: ${theme.breakpoints.medium}) {
    display: none;
  }
`;

const Title = styled.div`
  ${fonts.MaisonNeueDemi};
  font-size: 16px;
  line-height: 22px;
  color: ${theme.colors.black};
  text-align: left;
  @media (min-width: ${theme.breakpoints.medium}) {
    font-size: 18px;
    line-height: 28px;
  }
`;

const Text = styled.div`
  ${fonts.MaisonNeueLight};
  font-size: 16px;
  line-height: 22px;
  color: ${theme.colors.black};
  text-align: left;
  @media (min-width: ${theme.breakpoints.medium}) {
    font-size: 18px;
    line-height: 28px;
  }
  a {
    color: ${theme.colors.black};
    text-decoration: none;
    ${fonts.MaisonNeueLight};
    font-size: 16px;
    line-height: 22px;
  }
`;

const Copyrights = styled.div`
  ${fonts.MaisonNeueLight};
  font-size: 18px;
  line-height: 33px;
  color: ${theme.colors.darkGrey};
  white-space: nowrap;
`;

const SocialIcons = styled.div`
  display: flex;
  margin-left: -15px;
  margin-top: 10px;
  margin-bottom: 20px;
  @media (min-width: ${theme.breakpoints.medium}) {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const NewsLetter = styled.button`
  -webkit-tap-highlight-color: transparent;
  ${fonts.MaisonNeueDemi};
  font-size: 18px;
  line-height: 27px;
  color: ${theme.colors.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
`;
const Plus = styled.img`
  height: 12px;
  width: 12px;
  padding: 15px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform: ${(props) => props.open && "rotate(45deg)"};
  transform: ${(props) => props.open && "rotate(45deg)"};
  -webkit-transform: ${(props) => props.hover && "rotate(360deg)"};
  transform: ${(props) => props.hover && "rotate(360deg)"};
`;

const SubscriptionText = styled.div`
  ${fonts.MaisonNeueLight};
  font-size: 18px;
  line-height: 28px;
  color: ${theme.colors.darkGrey};
`;

const ContainerStyled = styled(Container)`
  height: 100%;
  @media (min-width: ${theme.breakpoints.medium}) {
    height: auto;
  }
`;

const SubscribeContainer = styled(Container)`
  height: ${(props) => (props.open ? "100%" : "0")};
  padding-bottom: 40px;
  transition: 0.4s;
`;

const Privacy = styled.div`
  ${fonts.MaisonNeueLight};
  font-size: 14px;
  line-height: 18px;
  color: ${theme.colors.darkGrey};
  text-align: right;
  white-space: nowrap;
  a {
    text-decoration: none;
    color: ${theme.colors.darkGrey};
  }
`;

const BottomRow = styled(Row)`
  width: 100%;
`

const url =
  "https://societystudios.us20.list-manage.com/subscribe/post?u=55e88c0c7be7e48e39ead974c&amp;id=317ff9c0e9";

class Footer extends Component {
  state = {
    signUpDisplayed: false,
    signUpHovered: false,
  };

  constructor(props) {
    super(props);
    this.pageEndRef = React.createRef();
  }

  displaySignUp = () =>
    this.setState({
      signUpDisplayed: !this.state.signUpDisplayed,
    });

  hoverOverSignUp = () =>
    this.setState({
      signUpHovered: !this.state.signUpHovered,
    });

  scrollToBottom = () => this.pageEndRef.current.scrollIntoView();

  componentDidUpdate() {
    if (this.state.signUpDisplayed) {
      this.scrollToBottom();
    }
  }

  render() {
    const { signUpDisplayed, signUpHovered } = this.state;
    const { displaySignUp, hoverOverSignUp, pageEndRef } = this;

    return (
      <React.Fragment>
        <FooterContainer>
          <ContainerStyled>
            <RowStyled>
              <ColStyled md={3} desktop>
                <Link to={"/"}>
                  <Logo src={DarkLogo} className="cursor-dot-black"/>
                </Link>
              </ColStyled>
              <ColStyled md={3} details>
                <Text className="cursor-dot-black">
                  <ExternalLink href="https://goo.gl/maps/MAvtttuqJvjei8GZ9">
                    <Title>Leeds</Title>
                    Arena Park,
                    <br />
                    Leeds, LS17 9BF
                    <br />
                  </ExternalLink>
                  <ExternalLink href="tel:+441132385471">
                    +44 (0)113 238 5471
                  </ExternalLink>
                </Text>
              </ColStyled>
              <ColStyled md={3} details>
                <Text className="cursor-dot-black">
                  <ExternalLink href="https://goo.gl/maps/KwksfukPS2KEzAq98">
                    <Title>London</Title>
                    Buckingham Palace Rd<br />
                    London, SW1W 9SA<br />
                  </ExternalLink>
                  <ExternalLink href="tel:+442045132582">
                    +44 (0)20 4513 2582
                  </ExternalLink>
                </Text>
              </ColStyled>
              <ColStyled md={3} details>
                <Text>
                  <Title> Question Everything. </Title>
                  <ExternalLink href="mailto:info@societystudios.co.uk">
                    <span className="cursor-dot-black">
                      info@societystudios.co.uk
                    </span>
                  </ExternalLink>
                </Text>
              </ColStyled>
              <LogoMobile src={DarkLogo} />
            </RowStyled>
          </ContainerStyled>
        </FooterContainer>
        <FooterDark open={signUpDisplayed} className="cursor-white">
          <ContainerStyled autoHeight open={signUpDisplayed}>
            <RowStyled open={signUpDisplayed} main>
              <ColStyled md={3} lgOrder={1} smOrder={2} xsOrder={2}>
                <SocialIcons>
                  <SocialLinks white={"true"} />
                </SocialIcons>
              </ColStyled>
              <ColStyled md={3} lgOrder={2} smOrder={1} xsOrder={1}>
                <NewsLetter
                  onClick={() => displaySignUp()}
                  onMouseEnter={hoverOverSignUp}
                  onMouseLeave={hoverOverSignUp}
                  hover={signUpHovered}
                  open={signUpDisplayed}
                >
                  <div>Society Newsletter </div>
                  <Plus
                    src={PlusIcon}
                    hover={signUpHovered}
                    open={signUpDisplayed}
                    className="cursor-dot-white"
                  />
                </NewsLetter>
              </ColStyled>
              <ColStyled
                md={6}
                lg={4}
                lgOffset={2}
                lgOrder={3}
                smOrder={3}
                xsOrder={3}
              >
                <BottomRow>
                  <Col xs={6} sm={6}>
                    <Copyrights>
                      © Society Studios _ {new Date().getFullYear()}
                    </Copyrights>
                  </Col>
                  <Col xs={3} xsOffset={3} smOffset={3} sm={3} alignSelf={"center"}>
                    <Privacy className="cursor-dot-white">
                      <Link to={"/privacy-policy"}>
                        Privacy Policy
                      </Link>
                    </Privacy>
                  </Col>
                </BottomRow>
              </ColStyled>
            </RowStyled>
          </ContainerStyled>
          {signUpDisplayed && (
            <SubscribeContainer autoHeight open={signUpDisplayed}>
              <RowStyled open={signUpDisplayed}>
                <ColStyled md={6} lg={3} lgOffset={3}>
                  <SubscriptionText>
                    Subscribe to our newsletter to stay informed about our
                    latest work and studio news. As well as information on
                    current positions and internships
                  </SubscriptionText>
                </ColStyled>
                <ColStyled md={6} lg={3} lgOffset={3}>
                  <MailchimpSubscribe url={url} />
                </ColStyled>
              </RowStyled>
              <div ref={pageEndRef} />
            </SubscribeContainer>
          )}
        </FooterDark>
      </React.Fragment>
    );
  }
}

export default Footer;
