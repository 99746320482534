import React from "react";
import styled from 'styled-components';
import fonts from '../../../styles/fonts';


const SignUpForm = styled.div`
    background: transparent;
    width: 100%;
   ${fonts.MaisonNeueLight};
`;

const BorderInputContainer = styled.div`
    position: relative;
    display: block;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 2px solid white;
    margin: 0 auto;
    input {
        background: transparent;
        border: none;
        border-radius: 0px;
        ${fonts.MaisonNeueLight};
        color: white;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0;
        width: 100%;
        box-sizing: border-box;
        padding: 10px 0 10px 15px;
        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            outline: none;
            box-shadow: none;
        }
        ::placeholder,
        ::-webkit-input-placeholder {
            color: white;
            font-size: 18px;
        }
        :-ms-input-placeholder {
            color: white;
            font-size: 18px;
        }
    } 
`;

const SimpleForm = ({ status, message, onSubmitted }) => {
    let input;
    const submit = () =>
        input &&
        input.value.indexOf("@") > -1 &&
        onSubmitted({
            EMAIL: input.value
        });

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            submit();
        }
    };

    return (
        <SignUpForm>
            <BorderInputContainer>
                <input
                    onKeyPress={handleKeyPress}
                    ref={node => (input = node)}
                    type="email"
                    placeholder="Your email address" />
            </BorderInputContainer>
            <div style={{
                height: "25px", marginTop: "25px"
            }}>
                {status === "error" && (
                    <div
                        style={{

                            fontSize: "18px",
                            lineHeight: "28px",
                            color: "#666666",
                            textAlign: "center"
                        }}
                        dangerouslySetInnerHTML={{ __html: message }}
                    />
                )}
                {status === "success" && (
                    <div
                        style={{

                            fontSize: "18px",
                            lineHeight: "28px",
                            color: "#666666",
                            textAlign: "center"
                        }}
                        dangerouslySetInnerHTML={{ __html: message }}
                    />
                )}
            </div>
        </SignUpForm>
    );
};

export default SimpleForm;

