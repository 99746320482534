const theme = {
    breakpoints : {
        small: '576px',
        medium: '768px',
        large: '1150px',
        xl: '1400px'
    },
    colors: {
        black: '#000000',
        white: '#FFFFFF',
        grey: '#E6E6E6',
        darkGrey: '#666666',
        greyBackground: '#F2F2F2',
        greyConnectBlock: '#1A1A1A',
        greyProjectImg: '#D5D5D5',
    }

};

export default theme;
