import React, {useEffect, useRef, useState} from "react";
import styled, {keyframes} from 'styled-components';
import theme from "../../styles/theme";
import breakpoints from "../../styles/breakpoints";
import html2canvas from "html2canvas";

const cursorAnimation = (isCircle, isDark) => keyframes`
  0% {
    height: ${isCircle? "8px": "22px"};
    width: ${isCircle? "8px": "22px"};
    background: ${isCircle|| isDark ? theme.colors.black : "transparent"};
  }
  100% {
    height: ${isCircle? "22px": "8px"};
    width: ${isCircle? "22px": "8px"};
    background: ${isCircle || isDark ? "transparent" : theme.colors.black};
  }
`;

const Cursor = styled.span`
 @media(min-width: ${breakpoints.medium}){
  height: ${props => props.isCircle? "22px": "8px"};
  width: ${props => props.isCircle? "22px" : "8px"};
  background: ${props => props.isCircle ? "transparent" : theme.colors.white};
  border: 2px solid ${props => props.isCircle && !props.isDark ? theme.colors.black : theme.colors.white};
  display: block;
  position: absolute;
  border-radius: 50%;
  z-index: 1111111111111122223;
  animation: ${props => cursorAnimation(props.isCircle, props.isDark)} 0.1s linear 1; }
`;

const CustomCursor = () => {
    let cursorRef = useRef(null);
    const [isCircle, setIsCircle] = useState(true);
    const [isDark, setIsDark] = useState(false);
    const [canvas, setCanvas] = useState(null);
    const elements = [
        'a', 'button', 'input', 'textarea'
    ];

    const onMouseMove = (event) => {
        cursorRef.current.style.top = event.pageY+"px";
        cursorRef.current.style.left = event.pageX+"px";

        if (canvas === null) {
            setCanvas(true);  // loading state
            setTimeout(() => html2canvas(document.querySelector("#gatsby-focus-wrapper")).then(screenshot => {
                // setTimeout(() => html2canvas(document.querySelector("#gatsby-focus-wrapper"))
                //     .then(screenshot =>  document.body.appendChild(screenshot)), 1000);
                setCanvas(screenshot.getContext('2d'))
            }), 1000);
        }

        if (canvas !== null && canvas !== true) {
            let p = canvas.getImageData(event.pageX, event.pageY, 1, 1).data;
            let lowerThan100 = p[0] < 150 && p[1] < 150 && p[2] < 150;
            if (isDark === false && lowerThan100 === true) {
                setIsDark(true)
            } else if (isDark === true && lowerThan100 === false) {
                setIsDark(false)
            }
        }
    };

    const onMouseOver = (event) => {

        if(!cursorRef || !event.target || !event.target.parentElement || event.target.className === cursorRef.current.className){
            return;
        }
        // console.log("Target",event.target)
        // console.log("Parent",event.target.parentElement)

        if (!elements.includes(event.target.localName) && !elements.includes(event.target.parentElement.localName)){
            if (isCircle === true) {
                return;
            }
            setIsCircle(true);
            return;
        }

        if (isCircle === false) {
            return;
        }

        setIsCircle(false);
    };

    useEffect(() => {
        window.addEventListener("mousemove", onMouseMove);
        window.addEventListener("mouseover", onMouseOver);
        return () => {
            window.removeEventListener('mouseover', onMouseOver);
            window.removeEventListener('mousemove', onMouseMove);
        }
    });

    return (
        <div>
            <Cursor ref={cursorRef} isCircle={isCircle} isDark={isDark}/>
        </div>
    )
};

export default CustomCursor