import React, {useState} from "react";
import InstagramIconGrey from "../../resources/images/profile_page/instagram-grey.svg";
import LinkedInIconGrey from "../../resources/images/profile_page/linkedin-grey.svg";
import TwitterIconGrey from "../../resources/images/profile_page/twitter-grey.svg";
import VimeoIconGrey from "../../resources/images/profile_page/vimeo-grey.svg";
import InstagramIconWhite from "../../resources/images/profile_page/instagram.svg";
import LinkedInIconWhite from "../../resources/images/profile_page/linkedin.svg";
import TwitterIconWhite from "../../resources/images/profile_page/twitter.svg";
import VimeoIconWhite from "../../resources/images/profile_page/vimeo.svg";
import InstagramIconBlack from '../../resources/images/home_page/instagram-black.svg';
import LinkedInIconBlack from '../../resources/images/home_page/linkedin-black.svg';
import TwitterIconBlack from '../../resources/images/home_page/twitter-black.svg';
import VimeoIconBlack from '../../resources/images/home_page/vimeo-black.svg';
import ExternalLink from "./ExternalLink";
import styled from "styled-components";
import theme from "../../styles/theme";

const SocialLink = styled.div`
  padding-left: 15px;
  padding-right: 15px;
`;

const SocialIcon = styled.img`
    height: 18px;
    width: auto;
    @media(min-width: ${theme.breakpoints.medium}) {
        height: 16px;
    }
`;

const SocialLinks = ( props) => {
    const [hoveredIcon, setIsHovered] = useState(null);
    const socialData = [
        {
            greyIcon: InstagramIconGrey,
            whiteIcon: InstagramIconWhite,
            blackIcon: InstagramIconBlack,
            alt: "Instagram",
            url: "https://www.instagram.com/society_studios"
        },
        {
            greyIcon: LinkedInIconGrey,
            whiteIcon: LinkedInIconWhite,
            blackIcon: LinkedInIconBlack,
            alt: "LinkedIn",
            url: "https://www.linkedin.com/company/society-studios"
        },
        {
            greyIcon: TwitterIconGrey,
            whiteIcon: TwitterIconWhite,
            blackIcon: TwitterIconBlack,
            alt: "Twitter",
            url: "https://twitter.com/society_agency"
        },
        {
            greyIcon: VimeoIconGrey,
            whiteIcon: VimeoIconWhite,
            blackIcon: VimeoIconBlack,
            alt: "Vimeo",
            url: "https://vimeo.com/societystudios"
        },
    ];

    return (
        <React.Fragment>
            {socialData.map((item, index) =>
                <SocialLink key={index} className={props.white ? "cursor-dot-white": props.black ? "cursor-dot-black" : "cursor-dot-white"}>
                    <ExternalLink href={item.url}>
                        <SocialIcon src={props.white ? (hoveredIcon === index ? item.greyIcon : item.whiteIcon) : props.black ? (hoveredIcon === index ? item.greyIcon : item.blackIcon) : ( hoveredIcon === index ?item.whiteIcon : item.greyIcon)}
                                    alt={item.alt}
                                    onMouseEnter={() => setIsHovered(index)}
                                    onMouseLeave={() => setIsHovered(null)}/>
                    </ExternalLink>
                </SocialLink>
            )}
        </React.Fragment>
    )
}

export default SocialLinks
